/*
 * @Author: 智客云网络科技
 * @Date: 2021-04-13 20:38:32 +0800
 * @LastEditors: 阡陌OvO
 * @LastEditTime: 2022-02-06 20:21:46
 * @Description: 更改store中状态的唯一方法
 * @FilePath: \src\store\mutations.js
 */

const mutations = {};

export default mutations;
