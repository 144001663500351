/*
 * @Author: 智客云网络科技
 * @Date: 2022-02-06 20:06:58
 * @LastEditors: 阡陌OvO
 * @LastEditTime: 2022-02-08 19:09:07
 * @Description: 路由状态管理模块
 * @FilePath: \src\store\MenuModule.js
 */

const module = {
  namespaced: true, // 命名空间
  // 存放数据的地方
  state: {
    originalNavBarConfig: [], //原始菜单表
    navBarConfig: [], //菜单表
    navBarStatus: false, //菜单初始化状态
    showNavBar: {}, //当前显示的菜单
  },

  //派生数据
  getters: {},

  //同步改变
  mutations: {
    //设置原始菜单表
    setOriginalNavBarConfig: (state, payload) => {
      state.originalNavBarConfig = payload;
    },

    //设置菜单表
    setNavBarConfig: (state, payload) => {
      state.navBarConfig = payload;
    },

    //设置菜单状态
    setNavBarStatus: (state, payload) => {
      state.navBarStatus = payload;
    },

    //设置显示的菜单
    setShowNavBar: (state, payload) => {
      state.showNavBar = payload;
    },
  },
  //异步改变
  actions: {},
};
//导出
export default module;
