/*
 * @Author: 智客云网络科技
 * @Date: 2022-02-06 18:38:21
 * @LastEditors: 阡陌OvO
 * @LastEditTime: 2022-02-08 15:14:07
 * @Description: 请输入文件描述信息
 * @FilePath: \src\router\routes.js
 */

const routes = [
  {
    path: "/",
    redirect: "/Home",
  },
  {
    id: "a6s28w1g74",
    title: "Main",
    name: "Main",
    path: "/",
    component: () => import("@/components/main/main.vue"),
    children: [
      //   {
      //     id: "home",
      //     title: "首页",
      //     name: "Home",
      //     path: "Home",
      //     component: () => import("@/views/Home/Home.vue"),
      //   },
      //   {
      //     id: "workbench",
      //     title: "工作台",
      //     name: "Workbench",
      //     path: "Workbench",
      //     component: () => import("@/views/Workbench/Workbench.vue"),
      //   },
      //   {
      //     id: "EntryManage",
      //     title: "入库扫描",
      //     name: "EntryManage",
      //     path: "EntryManage",
      //     component: () => import("@/views/StockManage/EntryManage.vue"),
      //   },
    ],
  },
  {
    id: "login",
    title: "Login",
    name: "Login",
    path: "/Login",
    component: () => import("@/views/Login.vue"),
  },
];

export default routes;
