/*
 * @Author: 智客云网络科技
 * @Date: 2022-02-06 19:48:53
 * @LastEditors: 阡陌OvO
 * @LastEditTime: 2022-02-06 21:09:09
 * @Description: 用户状态管理模块
 * @FilePath: \src\store\UserModule.js
 */

const module = {
  namespaced: true, // 命名空间
  // 公共全局状态
  state: {
    username: "", //用户名
    nickname: "", //昵称
    profilePhoto: "image/19700101/avatar-load-fail.png", //头像
    sign: "", //个签
    kor_name: "", //组织名称
    token: null, //Token值
    language: "zh_CN", //首选语言
    loginStatus: false, //登录状态
  },
  // 集中式修改状态的方法
  mutations: {
    //修改用户名
    setUserName: (state, payload) => {
      state.username = payload;
    },

    //修改昵称
    setNickName: (state, payload) => {
      state.nickname = payload;
    },

    //修改头像
    setProfilePhoto: (state, payload) => {
      state.profilePhoto = payload;
    },

    //修改Token
    setToken: (state, payload) => {
      state.token = payload;
    },

    //修改个签
    setSign: (state, payload) => {
      state.sign = payload;
    },

    //是否登录
    setLoginStatus: (state, payload) => {
      state.loginStatus = payload;
    },

    //首选语言
    setLanguage: (state, payload) => {
      state.language = payload;
    },

    //设置组织名称
    setKorName: (state, payload) => {
      state.kor_name = payload;
    },
  },
  // 异步请求的方法
  actions: {},
};
// 导出去
export default module;
