/*
 * @Author: 智客云网络科技
 * @Date: 2022-02-06 17:57:55
 * @LastEditors: 阡陌OvO
 * @LastEditTime: 2022-02-16 10:30:38
 * @Description: 请输入文件描述信息
 * @FilePath: \src\main.js
 */
import { createApp } from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import utils from "@/utils";
import Vant from "vant";
import { Lazyload } from "vant";
import "vant/lib/index.css";
import "./globa.css";
// import Vconsole from "vconsole";
// new Vconsole();

const app = createApp(App);

app.use(Vant);
app.use(Lazyload);
app.use(utils);
app.use(store);
app.use(router);
app.mount("#app");
