<!--
 * @Author: 智客云网络科技
 * @Date: 2022-02-06 17:57:55
 * @LastEditors: 阡陌OvO
 * @LastEditTime: 2022-02-06 22:10:42
 * @Description: 请输入文件描述信息
 * @FilePath: \src\App.vue
-->
<template>
  <router-view />
</template>

<script>
export default {
  name: "App",
};
</script>
