/*
 * @Author: 智客云网络科技
 * @Date: 2022-02-06 17:57:55
 * @LastEditors: 阡陌OvO
 * @LastEditTime: 2022-02-08 14:30:48
 * @Description: 请输入文件描述信息
 * @FilePath: \src\store\index.js
 */
import { createStore } from "vuex";
import actions from "./actions";
import mutations from "./mutations";
import UserModule from "./UserModule";
import RouterModule from "./RouterModule";
import MenuModule from "./MenuModule";

const state = {
  version: process.env.VUE_APP_APP_VERSION, //系统版本
  title: process.env.VUE_APP_DEFAULT_TITEL, //标题
  title_prefix: process.env.VUE_APP_TITEL_PREFIX, //标题前缀
};

const store = createStore({
  //全局状态
  state,
  mutations,
  actions,
  //模块
  modules: {
    UserModule,
    RouterModule,
    MenuModule,
  },
});

//将store store 导出去
export function myStore() {
  return store;
}

//导出
export default store;
